import axios from "axios";

export const mainApi = axios.create({
    baseURL: "https://ntmovel.com.br/api.php"
})


export const chipApi = axios.create({
    baseURL: "https://sistema.playmovel.com.br/api/"
})

