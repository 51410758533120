import React, { useState, Fragment, useEffect } from 'react';
import './assets/css/App.css';
import Logo from "./assets/img/nt-movel-logo.png"
import Telefone from "./assets/img/telefone.svg"
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import InputMask from 'react-input-mask';
import CpfCnpj from "@react-br-forms/cpf-cnpj-mask";
import {
  subCustumer,
  activatePlan,
  checkIccid as checkIccidData,
  getCustomer as getCustomerData,
  getPlans as getPlansData,
  getVerifyPayment
} from "./services/actions"

function App() {
  const MAX_STEP = 3;
  const [step, setStep] = useState(1);
  const [customerFetch, setCustomerFetch] = useState(false);
  const [plans, setPlans] = useState([]);
  const [cpfCnpj, setCpfCnpj] = useState("");
  const [mask, setMask] = useState("");
  const [iccid, setIccid] = useState("");
  const [validIccid, setValidIccid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [successData, setSuccessData] = useState({ success: false })
  const [customerData, setCustomerData] = useState({
    name: "",
    email: "",
    whats: "",
    nascimento: "",
    cep: "",
    uf: "",
    cidade: "",
    district: "",
    street: "",
    number: "",
    complement: ""
  });
  const [planInfo, setPlanInfo] = useState({
    ddd: "",
    planid: "",
    value: ""
  })

  async function getCustomer(cpfcnpj) {
    const response = await getCustomerData(cpfcnpj);

    if (response.data.status === 1) {
      setCustomerFetch(true);

      const data = response.data.data;
      const bornDate = data.borndate.split("-")
      setCustomerData({
        name: data.name || "",
        email: data.email || "",
        whats: data.whatsappnum || "",
        nascimento: `${bornDate[2]}-${bornDate[1]}-${bornDate[0]}` || "",
        cep: data.cep || "",
        uf: data.uf || "",
        cidade: data.city || "",
        district: data.district || "",
        street: data.street || "",
        number: data.number || "",
        complement: data.complement || ""
      })
    } else {
      setCustomerFetch(false);
    }

  }

  async function getPlans() {
    const { data } = await getPlansData();
    setPlans(data.personalizado);
  }

  async function checkIccid(iccid) {
    const response = await checkIccidData(iccid);
    if (response.status == 200) {
      const { data } = await getVerifyPayment(iccid, removeMask(cpfCnpj))
      if (data.status == 0) {
        setValidIccid(false)
        NotificationManager.warning(data.msg)
      } else {
        if (data.data == 0) {
          setValidIccid(false)
          NotificationManager.warning("Necessário efetuar o pagamento da compra do chip.")
        } else {
          setValidIccid(true)
        }
      }
    } else {
      setValidIccid(false)
    }
  }

  function setPlanSelected(e) {
    e.preventDefault();
    const p = [...plans]
    const planSelected = p.find(i => (i.planid == e.target.value && i.mostraApp));
    setPlanInfo({ ...planInfo, planid: e.target.value, value: planSelected.value })
  }

  async function activePlan(e) {
    e.preventDefault();
    setLoading(true);
    if (validIccid) {
      const cad = {
        ...customerData,
        cpf: removeMask(cpfCnpj),
        phone: String(customerData.whats),
        whats: String(customerData.whats),
        cidade: String(customerData.cidade),
        cep: String(customerData.cep),
        number: String(customerData.number),
        obs: "",
        parceiro: 'NTWEB MÓVEL',
        nivel: "3"
      }

      try {
        const sub = await subCustumer(cad);
        const planSetted = {
          ...planInfo,
          planid: String(planInfo.planid),
          iccid: iccid,
          cpf: cad.cpf,
          planid_personalizado: String(plans.find(i => i.planid == planInfo.planid && i.mostraApp).id)
        }

        try {
          const response = await activatePlan(planSetted);
          setLoading(false);
          setSuccessData({
            ...response.data,
            success: true
          });
        } catch (error) {
          setLoading(false);
          NotificationManager.warning(error.response.data)
        }

      } catch (error) {
        setLoading(false);
        NotificationManager.warning(error.response.data)
      }
    } else {
      setLoading(false);
      NotificationManager.warning("ICCID Inválido")
    }
  }

  function removeMask(cpfcnpj) {
    let cpf = cpfcnpj.replace(".", "")
    cpf = cpf.replace(".", "")
    cpf = cpf.replace("-", "")
    cpf = cpf.replace("/", "")
    cpf = cpf.replace(" ", "")
    cpf = cpf.replace("(", "")
    cpf = cpf.replace(")", "")
    return cpf
  }

  useEffect(() => {
    getPlans()
  }, [])

  useEffect(() => {
    if (removeMask(cpfCnpj).length === 11 || removeMask(cpfCnpj).length === 14) {
      getCustomer(removeMask(cpfCnpj));
    }
  }, [cpfCnpj]);

  useEffect(() => {
    if (iccid.length == 19) {
      checkIccid(iccid)
    }
  }, [iccid])

  return (

    <div className="App">
      <header>
        <div className="header">
          <a href='/' className='logo-field'>
            <img src={Logo} alt="Logo NT Móvel" className='logo-img' />
          </a>
          <div className='info-field'>
            <h1>Ativação de chip NT MÓVEL</h1>
            <a href='https://painel.ntmovel.com.br' className='painel-link'>
              Painel Administrativo
            </a>
          </div>
        </div>
      </header>
      <section className="content">
        <article className="text">
          <h2>Parabéns por adquirir seu novo chip NT Móvel</h2>
          <p>Você está quase pronto para pode aproveitar sua internet com gigas que não expiram, WhatsApp Ilimitado e Cobertura Nacional. Para isso você precisa ativar seu chip, preenchendo o formulário abaixo:</p>

          {!successData.success ?
            <Fragment>
              {(removeMask(cpfCnpj).length === 11 || removeMask(cpfCnpj).length === 14) &&
                <div className="controlField">
                  {step > 1 &&
                    <button
                      className="controlButton"
                      onClick={(e) => {
                        e.preventDefault();
                        if (step > 1) {
                          setStep(step - 1);
                        }
                      }}
                    >
                      Anterior
                    </button>}
                  <p className="steps">{step} / {MAX_STEP}</p>
                  {step < MAX_STEP &&
                    <button
                      className="controlButton"
                      onClick={(e) => {
                        e.preventDefault();
                        if (step < MAX_STEP) {
                          setStep(step + 1);
                        }
                      }}
                    >
                      Próximo
                    </button>}
                </div>
              }

              {step === 1 && <Fragment>
                <CpfCnpj
                  value={cpfCnpj}
                  placeholder="CPF / CNPJ"
                  className='form-control'
                  onChange={(ev, type) => {
                    setCpfCnpj(ev.target.value);
                    setMask(type === "CPF");
                  }}
                />
                <input
                  type="text"
                  className='form-control'
                  placeholder='Nome'
                  value={customerData.name}
                  disabled={customerFetch}
                  readOnly={customerFetch}
                  name="name"
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
                <InputMask mask="99-99-9999" className='form-control'
                  placeholder='Data de Nascimento / Abertura de Empresa'
                  value={customerData.nascimento}
                  disabled={customerFetch}
                  readOnly={customerFetch}
                  name="nascimento"
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
                <input
                  type="text"
                  className='form-control'
                  placeholder='Email'
                  name="email"
                  value={customerData.email}
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
                <InputMask mask="(99)99999-9999"
                  type="text"
                  className='form-control'
                  placeholder='Telefone para receber token'
                  name="whats"
                  value={customerData.whats}
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: removeMask(e.target.value) })} />
              </Fragment>}
              {step === 2 && <Fragment>
                <input
                  type="text"
                  className='form-control'
                  placeholder='CEP'
                  name="cep"
                  value={customerData.cep}
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
                <input
                  type="text"
                  className='form-control'
                  placeholder='UF'
                  name="uf"
                  value={customerData.uf}
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
                <input
                  type="text"
                  className='form-control'
                  placeholder='Cidade'
                  name="cidade"
                  value={customerData.cidade}
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
                <input
                  type="text"
                  className='form-control'
                  placeholder='Bairro'
                  name="district"
                  value={customerData.district}
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
                <input
                  type="text"
                  className='form-control'
                  placeholder='Endereço'
                  name="street"
                  value={customerData.street}
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
                <input
                  type="text"
                  className='form-control'
                  placeholder='Número'
                  name="number"
                  value={customerData.number}
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
                <input
                  type="text"
                  className='form-control'
                  placeholder='Complemento'
                  name="complement"
                  value={customerData.complement}
                  onChange={(e) => setCustomerData({ ...customerData, [e.target.name]: e.target.value })} />
              </Fragment>}
              {step === 3 && <Fragment>
                <input
                  type="text"
                  className='form-control'
                  placeholder='DDD'
                  name="ddd"
                  value={planInfo.ddd}
                  onChange={(e) => setPlanInfo({ ...planInfo, [e.target.name]: e.target.value })} />
                <input
                  type="text"
                  className='form-control'
                  placeholder='ICCD'
                  value={iccid}
                  maxLength={19}
                  onChange={(e) => setIccid(e.target.value)} />
                <select
                  type="text"
                  className='form-control'
                  placeholder='Plano'
                  name="planid"
                  value={planInfo.planid}
                  onChange={(e) => setPlanSelected(e)}>
                  <option>Escolha seu plano:</option>
                  {plans.map((value) => {
                    if(value.mostraApp) return <option key={value.id} value={value.planid}>{value.description}</option>
                  })}
                </select>
                <input
                  type="text"
                  className='form-control'
                  placeholder='Valor'
                  name="value"
                  value={Number(planInfo.value).toLocaleString("pt-br", { minimumFractionDigits: 2, style: 'currency', currency: 'BRL' })}
                  disabled
                  readOnly />
                <button className="form-button" onClick={(e) => activePlan(e)}>Ativar meu chip</button>

              </Fragment>}
            </Fragment>
            :
            <Fragment>
              <p>{successData.msg}</p>
              <a href={`https://fatura.operadora.app.br/?payid=${successData.fatura}`} rel="noreferrer" target="_blank" className="activeChip">Ativar agora</a>
            </Fragment>
          }
        </article>
        <img src={Telefone} alt="Chip NT Móvel" className='telefone-img' />
      </section>
      {loading && <div className='loading-field'>
        <div className='loading'></div>
      </div>}
      <NotificationContainer />
    </div >

  );
}

export default App;
