import {mainApi, chipApi} from "./api";

export const getCustomer = (cpfCnpj) => {
  const baseUrl = `?action=VERIFY-CUSTOMER&cpfcnpj=${cpfCnpj}`
  return mainApi.get(baseUrl)
}

export const getVerifyPayment = (iccid, cpfcnpj) => {
  const baseUrl = `?action=VERIFY-PAYMENT&iccid=${iccid}&cpfcnpj=${cpfcnpj}`
  return mainApi.get(baseUrl)
}

export const getPlans = () => {
  const baseUrl = `/planos/visualizarPlanosPersonalizados`
  return chipApi.post(baseUrl, {
    "token": "ce5f11566c56cdc50d089ad322e9a77906f2ba6b5cd910b09e"
  })
}

export const checkIccid = (iccid) => {
  const baseUrl = `/planos/iccid`
  return chipApi.post(baseUrl, {
    iccid,
    "token": "ce5f11566c56cdc50d089ad322e9a77906f2ba6b5cd910b09e"
  })
}

export const subCustumer = (cad) => {
  const baseUrl = `/cad`
  return chipApi.post(baseUrl, {
    ...cad,
    "token": "ce5f11566c56cdc50d089ad322e9a77906f2ba6b5cd910b09e"
  })
}

export const activatePlan = (plan) => {
  const baseUrl = `/ativacao/ativar`
  return chipApi.post(baseUrl, {
    ...plan,
    "token": "ce5f11566c56cdc50d089ad322e9a77906f2ba6b5cd910b09e"
  })
}